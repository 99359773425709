const TranscribeOutput = ({classes, transcribedText, interimTranscribedText}) => {
  console.log('Transcribing Output:', transcribedText)

  return null;
  /* Return nothing to not have things pop up
  if (transcribedText.length === 0 && interimTranscribedText.length === 0) {
    return <Typography variant="body1">...</Typography>;
  }

  return (
    <div className={classes.root}>
      <Typography variant="body1">{transcribedText}</Typography>
      <Typography className={classes.outputText} variant="body1">{interimTranscribedText}</Typography>
    </div>
  )
  */
}

export default TranscribeOutput;
