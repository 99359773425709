import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import "./emailCapture.css";


const EmailCapture = ({ setEmail }) => {
  const [emailInput, setEmailInput] = useState("");
  const navigate = useNavigate();
  const approvedEmails = ["goshkokosama@gmail.com", "test@test.com", "test3@test.com", 'tecko@gmail.com', 'teo@gmail.com', 'pesho@gmail.com'];


  const handleSubmit = (e) => {
    e.preventDefault();
    if(approvedEmails.includes(emailInput)) {
      setEmail(emailInput);
      navigate('/instructions');
    } else {
      // Display an error message
      alert("This email is not approved.");
    }
  };
  

  return (
    <Form onSubmit={handleSubmit} className="email-capture">
      <Form.Group>
        <Form.Label>Email:</Form.Label>
        <Form.Control
          type="email"
          value={emailInput}
          onChange={(e) => setEmailInput(e.target.value)}
          required
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

export default EmailCapture;
