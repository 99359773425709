import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { useState, useEffect } from 'react';


export function createNewSessionId() {
    let sessionId = uuidv4();
    Cookies.set('sessionId', sessionId);
    return sessionId;
}

export const useSession = () => {
    const [sessionId, setSessionId] = useState(Cookies.get('sessionId') || createNewSessionId());
  
    useEffect(() => {
      setSessionId(createNewSessionId());
    }, []);
  
    const restartSession = () => {
      let newSessionId = createNewSessionId();
      setSessionId(newSessionId);
    };
  
    return { sessionId, restartSession };
};

export function getSessionId() {
    return Cookies.get('sessionId');
}
