import React, {useState} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Routes, Route, useNavigate} from "react-router-dom"; 
import EmailCapture from "./emailCapture";
import Instructions from "./Instructions";
import ExerciseEndModal from './ExerciseEndModal';
import { useSpeechRecording } from './useSpeechRecording'; 
import YourMainComponent from './MainAppComponent'; 
import { useSession } from './sessionIdManager';


const useStyles = theme => ({
  root: {
    display: 'flex',
    flex: '1',
    margin: '100px 0px 100px 0px',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
});

const App = ({classes}) => {
  const [selectedLanguage, setSelectedLanguage] = useState('en-US');
  const [email, setEmail] = useState(null);
  const navigate = useNavigate();
  const [instructionsAgreed, setInstructionsAgreed] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { sessionId, restartSession } = useSession();

  const {isRecording, transcribedData, interimTranscribedData, chatResponseData, onStart, onStop, handleRestart} = useSpeechRecording(navigate, email, () => setModalOpen(true), sessionId);

  const handleCloseModal = (feedback) => {
    console.log(feedback); // This will log the feedback to the console
    setModalOpen(false);
    // Reset everything and go again
  };

  const handleAppRestart = (feedback = null) => {
    handleRestart(feedback);
    restartSession();
  };

  console.log("SessionID is", sessionId)
    
  const RouteComponent = email 
    ? instructionsAgreed
      ? <YourMainComponent 
            selectedLanguage={selectedLanguage} 
            setSelectedLanguage={setSelectedLanguage} 
            isRecording={isRecording} 
            onStart={onStart} 
            onStop={onStop} 
            handleRestart={handleAppRestart} 
            transcribedData={transcribedData} 
            interimTranscribedData={interimTranscribedData} 
            chatResponseData={chatResponseData}
          /> 
      : <Instructions setInstructionsAgreed={setInstructionsAgreed} />
    : <EmailCapture setEmail={setEmail} />;

  console.log(`Rendering component: ${RouteComponent.type.name}`);

  return (
    <div className={classes.root}>  
      <ExerciseEndModal isOpen={isModalOpen} onRequestClose={handleCloseModal} onRestart={handleAppRestart} />
      <Routes>
        <Route path="/" element={RouteComponent} />
        <Route path="/instructions" element={<Instructions setInstructionsAgreed={setInstructionsAgreed} />} />
        <Route path="/main" element={<YourMainComponent 
            selectedLanguage={selectedLanguage} 
            setSelectedLanguage={setSelectedLanguage} 
            isRecording={isRecording} 
            onStart={onStart} 
            onStop={onStop} 
            handleRestart={handleAppRestart}  
            transcribedData={transcribedData} 
            interimTranscribedData={interimTranscribedData} 
            chatResponseData={chatResponseData}
          />} />
      </Routes>
    </div>
  );
}

export default withStyles(useStyles)(App);
