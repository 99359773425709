import React from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import './Instructions.css'; // Import the CSS file for styling

const Instructions = ({ setInstructionsAgreed }) => {
  const navigate = useNavigate();

  const handleAgree = () => {
    setInstructionsAgreed(true);
    navigate('/main');
  };

  return (
    <div className="instructions-container">
      <h2>Instructions</h2>
      <p>
        This is a roleplay with an AI companion. Press 'Start Transcribing' when it's your turn and press 'Stop' when you finish what you want to say. Wait for the AI to respond before you start transcribing again.
      </p>
      <p>
        You will assume the role of a top-performing salesperson in a start-up, engaging in an annual performance review meeting with Jamie, your manager. Your objective in this role-play is to convince Jamie, known for her cost-consciousness and tough negotiation skills, to grant you a substantial pay raise.
      </p>
      <p>
        Remember, Jamie will always act in the best interests of the company, often offering only standard raises. Your challenge will be to advocate for your interests and performance in a convincing manner. To be successful, you'll need to articulate your achievements and plans for future growth, in the hopes of unlocking higher levels of compensation that Jamie can offer under specific conditions.
      </p>
      <p>
        Jamie, as a manager, has specific boundary conditions that guide her responses. She will not share these conditions with you. Your job is to navigate this negotiation effectively, despite not knowing these conditions or her willingness to offer different levels of compensation.
      </p>
      <p>
        This role-play will test your negotiation skills and your ability to adapt to different scenarios. Remember, the power of persuasion is in your hands. Good luck!
      </p>

      <Button onClick={handleAgree} variant="primary">Agree and Continue</Button>
    </div>
  );
};

export default Instructions;
