import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button, Form } from 'react-bootstrap';
import { styled } from '@material-ui/core/styles';

Modal.setAppElement('#root');

const StyledForm = styled(Form)({
  maxWidth: '90%',
  margin: '0 auto',
  textAlign: 'right',
  padding: '10px',
});

const StyledButton = styled(Button)({
  width: '20%',
  float: 'right',
  border: 'none',
  color: 'white',
  padding: '10px 16px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '14px',
  cursor: 'pointer',
  backgroundColor: '#0d6efd',
});

const ExerciseEndModal = ({ isOpen, onRequestClose, onRestart }) => {
  const [feedback, setFeedback] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onRequestClose(feedback);
    onRestart(feedback);
  };


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(feedback)}
      contentLabel="Exercise Completed Modal"
    >
      <h2>Well Done!</h2>
      <StyledForm onSubmit={handleSubmit}>
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Control
            as="textarea"
            rows={5}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder="Enter your feedback here..."
          />
        </Form.Group>
        <div className="d-flex justify-content-end">
          <StyledButton type="submit">Submit Feedback and Go Again</StyledButton>
        </div>
      </StyledForm>
    </Modal>
  );
};

export default ExerciseEndModal;
