import React from "react";
import {Button} from "react-bootstrap";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import SettingsSections from "./SettingsSection";
import TranscribeOutput from "./TranscribeOutput";
import { MessageBox } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';

const useStyles = theme => ({
  root: {
    display: 'flex',
    flex: '1',
    margin: '100px 0px 100px 0px',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  title: {
    marginBottom: '20px',
  },
  settingsSection: {
    marginBottom: '20px',
  },
  buttonsSection: {
    marginBottom: '40px',
  },
});

const supportedLanguages = {'en-US': 'English', 'de-DE': 'German', 'fr-FR': 'French', 'es-ES': 'Spanish'}

const MainAppComponent = ({ classes, selectedLanguage, setSelectedLanguage, isRecording, onStart, onStop, handleRestart, transcribedData, interimTranscribedData, chatResponseData }) => {
  
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h3">
          Jamie AI <span role="img" aria-label="microphone-emoji">🎤</span>
        </Typography>
      </div>
      <div className={classes.settingsSection}>
        <SettingsSections possibleLanguages={supportedLanguages} selectedLanguage={selectedLanguage}
                          onLanguageChanged={setSelectedLanguage}/>
      </div>
      <div className={classes.buttonsSection}>
        {!isRecording && <Button onClick={() => onStart(selectedLanguage)} variant="primary">Start transcribing</Button>}
        {isRecording && <Button onClick={() => onStop(false)} variant="danger">Stop</Button>}
        <Button onClick={() => handleRestart()} variant="secondary">Restart</Button>
      </div>
      <div>
        <TranscribeOutput transcribedText={transcribedData} interimTranscribedText={interimTranscribedData}/>
      </div>
      <div>
        {chatResponseData.map((response, index) => (
          <MessageBox
            key={index}
            position={response.author === 'bot' ? 'left' : 'right'}
            title={response.author === 'bot' ? 'Jamie' : 'You'}
            type={'text'}
            text={response.text}
          />
        ))}
      </div>
    </div>
  );
}

export default withStyles(useStyles)(MainAppComponent);
