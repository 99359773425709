import { useState } from 'react';
import speechToTextUtils from './utility_transcribe';

export function useSpeechRecording(navigate, email, onEnd, sessionId) {
  const [isRecording, setIsRecording] = useState(false);
  const [transcribedData, setTranscribedData] = useState([]);
  const [interimTranscribedData, setInterimTranscribedData] = useState('');
  const [chatResponseData, setChatResponseData] = useState([]); // New state to hold chat response

  console.log ("Initialising useSpeechRecording")

  function flushInterimData() {
    if (interimTranscribedData !== '') {
      setInterimTranscribedData('')
      setTranscribedData(oldData => [...oldData, interimTranscribedData])
    }
  }

  function handleDataReceived(data, isFinal) {
    if (isFinal) {
      setInterimTranscribedData('')
      setTranscribedData(oldData => [...oldData, data])
      // Add the transcribed data to chatResponseData with author as 'human'
      setChatResponseData(oldData => [...oldData, {author: 'human', text: ''}]);

    } else {
      setInterimTranscribedData(data)
    }
  }

  function getTranscriptionConfig(selectedLanguage) {
    return {
      audio: {
        encoding: 'LINEAR16',
        sampleRateHertz: 16000,
        languageCode: selectedLanguage,
      },
      interimResults: true,
      sessionId: sessionId,
      email: email
    }
  }

  function onStart(selectedLanguage) {
    if (!email) {
      // Navigate to the email capture page if email is not set
      navigate("/");
      return;
    }
    
    setTranscribedData([])
    setIsRecording(true)

    speechToTextUtils.initRecording(
      getTranscriptionConfig(selectedLanguage),
      handleDataReceived,
      (error) => {
        console.error('Error when transcribing', error);
        setIsRecording(false)
        // No further action needed, as stream already closes itself on error
      },
      (chatResponse) => {
        console.log("Received chat response:", chatResponse);
        setChatResponseData(oldData => [...oldData, {author: 'bot', text: chatResponse}]); 
        if (chatResponse === "###END###") {
            onEnd();
        }  
      });

    console.log(`Recording started with language ${selectedLanguage}`);

  }

  function onStop(isRestart, feedbackMessage) {
    setIsRecording(false)
    flushInterimData() // A safety net if Google's Speech API doesn't work as expected, i.e. always sends the final result
    speechToTextUtils.stopRecording(isRestart, feedbackMessage);

    console.log(`Recording stopped. Is part of restart: ${isRestart}`);
  }

  function handleRestart(feedbackMessage = null) {
    console.log('Restart operation triggered');
    onStop(true, feedbackMessage);
    setTranscribedData([]);
    setInterimTranscribedData('');
    setChatResponseData([]);
  }

  return {isRecording, transcribedData, interimTranscribedData, chatResponseData, onStart, onStop, handleRestart};
}
